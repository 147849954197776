import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close(evt) {
    this.element.classList.add("is-moving-offscreen-left")
  }

  closeAndSubmit(evt) {
    evt.preventDefault()
    this.element.closest("div").classList.add("is-blinking")
    setTimeout(() => { this.element.requestSubmit() }, 10)
  }
}
